<template>
  <!-- Registrer ny bruker-->
  <div class="col-md-12" style="margin-top: 3%; margin-bottom: 3%">
    <div class="card card-container">
      <div class="card-header" style="background-color: #212529; color: white">
        <h5><i class="fas fa-user-plus" alt="Registrerdegikon"></i> Registrer deg her</h5>
      </div>
      <div style="background-color: #212529; color: white">
        <div>
          <Form @submit="handleRegister" :validation-schema="schema">
            <div v-if="!successful">
              <div class="form-group">
                <label for="email"> E-post </label>
                <Field name="email" type="email" class="form-control" />
                <ErrorMessage name="email" class="error-feedback" />
              </div>
              <div class="form-group">
                <label for="password"> Passord </label>
                <Field name="password" type="password" class="form-control" />
                <ErrorMessage name="password" class="error-feedback" />
                <div class="form-group">
                  <label for="password_confirmation"> Gjenta passordet </label>
                  <Field
                    name="password_confirmation"
                    type="password"
                    class="form-control"
                  />
                  <ErrorMessage
                    name="password_confirmation"
                    class="error-feedback"
                  />
                </div>
              </div>
              <div class="form-group">
                <button class="btn btn-primary btn-block btn-success" style="margin-top: 16px" :disabled="loading">
                  <span
                    v-show="loading"
                    class="spinner-border spinner-border-sm"
                  ></span>
                  Registrer
                </button>
              </div>
            </div>
          </Form>
          <div
            v-if="message"
            class="alert"
            :class="successful ? 'alert-success' : 'alert-danger'"
          >
            {{ message }}
          </div>
          <use-conditions-modal></use-conditions-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UseConditionsModal from "../../components/UseConditionsModal.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
export default {
  name: "Register",
  components: {
    Form,
    Field,
    ErrorMessage,
    UseConditionsModal,
  },
  data() {
    const schema = yup.object().shape({
      email: yup
        .string()
        .required("E-post er påkrevet ")
        .email("E-posten er i ugyldig format ")
        .max(250, "E-posten må være under 250 tegn! "),
      password: yup
        .string()
        .required("Passord")
        .min(8, "Passordet må være på minst 8 tegn ")
        .max(100, "Passordet må være på under 100 regn "),
      password_confirmation: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passordene må være like ")
        .required("Du må gjenta passordet "),
    });
    return {
      successful: false,
      loading: false,
      message: "",
      schema,
    };
  },

  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },

  mounted() {
    if (this.loggedIn) {
      this.$router.push("/profile");
    }
  },

  methods: {
    sleep(milliseconds) {
      const date = Date.now();
      let currentDate = null;
      do {
        currentDate = Date.now();
      } while (currentDate - date < milliseconds);
    },

    handleRegister(user) {
      this.message = "";
      this.successful = false;
      this.loading = true;
      this.$store.dispatch("auth/register", user).then(
        (data) => {
          this.message = data.message;
          this.successful = true;
          this.loading = false;
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.successful = false;
          this.loading = false;
        }
      );
    },
  },
};
</script>

<style scoped>
.card-container.card {
  max-width: 500px !important;
}

.card {
  background-color: #212529;
  padding: 16px;
  margin: 0 auto;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
}

/* Input focus white glow */
.form-control:focus {
  border-color: white;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px white;
}
</style>
