<template>
  <div class="conditions">
    <p>Ved å registrere deg på denne siden godtar du våre</p>
    <a class="link" href="#" v-b-modal.modal-tall>Bruksvilkår</a>
    <b-modal id="modal-tall" title="Bruksvilkår" ok-only ok-variant="success" ok-title="OK">
      <p class="my-4">

        <b>PERSONVERNERKLÆRING</b>
        <br /><br />
        <I>Denne personvernerklæringen beskriver hvordan Norsk Meteornettverk samler 
        inn og behandler personopplysninger på denne siden. Som behandlingsansvarlig 
        har Norsk Meteornettverk det øverste ansvaret for å sikre at håndtering og
        behandling av personopplysninger skjer i samsvar med gjeldene lovgivning.</I>
        <br /><br />

        <i>Norsk Meteornettverk vil ikke dele eller bruke personopplysninger til andre 
        formål enn de som er angitt i denne erklæringen.</i>
        <br /><br/>

        <b>1. Innsamling av data</b>
        <br /><br />
        Følgende data henter vi om deg:
        <br/><ul>
          <li>
          Teknisk informasjon, som operativsystem, nettleser og IP-adresse samt 
          informasjon om din plassering. Denne informasjonen samles inn ved hjelp 
          av informasjonskapsler/cookies (se våre retningslinjer for cookies).
          </li>
          <li>
          Bevegelse på nett. Vi bruker Google Analytics for å få informasjon om 
          bruken av nettsiden vår. Google Analytics bruker cookies som innhenter 
          brukernes IP-adresser, og som analyserer brukernes bevegelser på nettet. 
          På denne måten kan vi se hvor lenge brukerne besøker nettstedet vårt, hvilke 
          nettsteder brukerne kommer fra, og hvilke nettlesere som brukes. Data knyttet 
          til disse informasjonskapslene blir lagret på servere i USA. Informasjonen 
          slettes 24 måneder etter registrering, og informasjonen som mottas er underlagt 
          Googles personvernregler.</li></ul>
        <br/>
       	<b>2. Bruk av data</b>
        <br/><br/>
          All informasjon vi samler inn kan brukes til å:
          <br /><ul>
          <li>Tilpasse brukeropplevelsen individuelt til hver bruker</li>
          <li>Tilby tilpasset annonsørinnhold</li>
          <li>Forbedre nettstedet vårt</li>
          <li>Kontakte deg på e-post</li>
          <li>Analysere bruk og bevegelser på nettstedet</li></ul>
        <br/><br/>
        	<b>3. Lagring og sikring av data</b>
          <br/><br/>
          Norsk Meteornettverk har strenge rutiner for å sikre at behandling av data skjer 
          i henhold til norsk lovverk, og at uvedkommende ikke har tilgang til dine 
          personopplysninger. Dine data lagres så lenge det nødvendig for formålet som 
          opplysningene samles inn for, og derfor så kort som mulig
        <br /><br />
        	<b>4. Formål</b>
          <br/><br/>
          Norsk Meteornettverk har en genuin interesse av å behandle dine personopplysninger 
          fra et forretningsperspektiv, og på en måte som ikke fratar deg dine rettigheter. 
          Formålet med databehandlingen er å tilpasse brukeropplevelsen til hver enkelt bruker, 
          tilpasse annonsørinnholdet, og analysere bruk og bevegelser på nettsiden og på den 
          måten forbedre nettstedet vårt
          <br/><br />
          <b>5. Tredjeparter</b>
          <br /><br />
          Vi verken selger, utveksler eller overfører dine personopplysninger på noen annen måte 
          enn det som er beskrevet i denne erklæringen. Dine data vil bare bli delt hvis vi er 
          juridisk forpliktet til det, eller hvis vi får ditt samtykke.
        <br /><br />
        	<b>6. Cookies</b>
        <br /><br />
          Når du laster ned en nettside, lagres små tekstfiler (informasjonskapsler) på enheten din. 
          Våre informasjonskapsler forbedrer tilgangen til nettstedet vårt og identifiseres besøkende. 
          I tillegg forbedrer våre informasjonskapsler brukeropplevelsen ved å spore og målrette 
          brukerens interesser. Denne bruken av informasjonskapsler er ikke knyttet til personlig 
          identifiserbar informasjon på nettstedet vårt.  Hvis du ønsker å unngå bruk av 
          informasjonskapsler, kan du sette nettleseren din til å ikke akseptere dette via 
          innstillinger. Vær oppmerksom på at nettsiden kanskje ikke fungerer optimalt hvis du velger 
          å ekskludere informasjonskapsler.
          <br /><br />
          <b>7. Dine rettigheter</b>
          <br/><br/>
          I henhold til personopplysningsloven og personvernforordningen har du rettigheter i forhold til 
          vår behandling av personopplysninger om deg:
          <br/><br/>
          <ul>
          <li>Rett til innsyn: Du har rett til innsyn i dine egne personopplysninger, og kan be om en kopi av 
          all informasjon vi har lagret om deg.</li>
          <li>Rett til korrigering: Du kan be oss om å korrigere uriktige opplysninger om deg. Du må kunne bevise 
          at opplysningene er feil.</li>
          <li>Rett til å slette: Du kan når som helst be oss om å slette informasjon om deg selv.</li>
          <li>Rett til begrensning: I noen tilfeller har du rett til å få begrenset behandlingen av opplysningene dine.</li>
          <li>Portabilitet: i noen tilfeller har du rett til å få overført personopplysningene dine til en annen 
          behandlingsansvarlig, i tillegg til å motta din egen informasjon i et strukturert og maskinlesbart format.</li>
          <li>Klage: Du kan klage på vår behandling av personopplysninger dersom du mener at opplysningene dine ikke 
          blir behandlet i samsvar med personopplysningsloven.</li></ul>
          <br /><br />
          <b>8. Endringer i denne erklæringen</b>
          <br/><br/>
          Hvis vi gjør endringer i denne personvernerklæringen, vil vi legge ut den reviderte versjonen her med 
          oppdatert dato. Vi oppfordrer alle våre brukere til å gjennomgå erklæringen regelmessig. Denne 
          personvernerklæringen ble sist oppdatert 24.05.2022.
          <br /><br />
          <b>9. Enighet</b>
          <br/><br/>
          Ved å bruke nettstedet vårt, godtar du vår personvernerklæring.
          <br /><br />
          <b>10. Kontaktinformasjon</b>
          <br/><br/>
          Hvis du har noen spørsmål om denne personvernerklæringen, vennligst kontakt oss 
          på <a href="mailto:steinar@norskmeteornettverk.no">epost</a> 
      </p>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'conditionsModal',
}
</script>

<style scoped>
.conditions {
  color: white;
  padding-top: 1em;
  text-align: center;
}
a.link {
  color: white;
}
a.link:hover {
  color: #9b9d9e;
}
</style>
